import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Markdown from 'markdown-to-jsx'
import styled from 'styled-components'

import { SEO } from '../components'
import { Container } from '../styled'

const AboutContainer = styled(Container)`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-family: retiro-std-24pt, sans-serif;
    font-size: 48px;
    margin-bottom: 60px;
  }

  img {
    margin: auto;
    max-width: 600px;
    width: 100%;
  }

  .description {
    margin-top: 40px;
  }
`

const About = ({ path }: any) => {
  const { t } = useTranslation()
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "ursula.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title={t('aboutUs')} path={path} />
      <AboutContainer>
        <h2>{t('aboutUs')}</h2>
        <img
          src={file?.childImageSharp?.fluid?.src}
          srcSet={file?.childImageSharp?.fluid?.srcSet}
          alt={t('aboutUs')}
          title={t('aboutUs')}
        />
        <div className="description">
          <Markdown>{t('bym.aboutBYM')}</Markdown>
        </div>
      </AboutContainer>
    </>
  )
}

export default About
